@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&display=swap");

* {
    font-family: 'Sarabun', sans-serif;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: .25rem !important;
}

.m-2 {
    margin: .5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.mt-1 {
    margin-top: .25rem !important;
}

.mr-1 {
    margin-right: .25rem !important;
}

.mb-1 {
    margin-bottom: .25rem !important;
}

.ml-1 {
    margin-left: .25rem !important;
}

.mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
}

.mt-2 {
    margin-top: .5rem !important;
}

.mr-2 {
    margin-right: .5rem !important;
}

.mb-2 {
    margin-bottom: .5rem !important;
}

.ml-2 {
    margin-left: .5rem !important;
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.my-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: .25rem !important;
}

.p-2 {
    padding: .5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.pt-1 {
    padding-top: .25rem !important;
}

.pr-1 {
    padding-right: .25rem !important;
}

.pb-1 {
    padding-bottom: .25rem !important;
}

.pl-1 {
    padding-left: .25rem !important;
}

.px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
}

.pt-2 {
    padding-top: .5rem !important;
}

.pr-2 {
    padding-right: .5rem !important;
}

.pb-2 {
    padding-bottom: .5rem !important;
}

.pl-2 {
    padding-left: .5rem !important;
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pr-5 {
    padding-right: 3rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.pl-5 {
    padding-left: 3rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.progress-bar-gray-container-custom {
    background-color: #d0cfcf;
    border-radius: 50px;
    overflow: hidden
}

.progress-bar-container-custom {
    background-color: #eb445a;
    border-radius: 50px;
    overflow: hidden;
}

.label-progress-bar-custom {
    color: #fff;
    font-weight: bold;
    margin-left: 60px;
    white-space: nowrap;
}

.progress-type-label {
    display: flex;
    justify-content: space-between;
}

.grid-progress-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.box-shadow-default {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px;
}

.change-image-profile-button {
    width: 40px;
    height: 40px;
    position: relative;
    right: -50px;
    bottom: 40px;
}

.flex-box-center {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-circle {
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.line-horizontal {
    width: 100%;
    border-bottom: solid 1.5px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))
}

.text-label-item {
    display: block;
    color: var(--ion-text-color);
    font-size: 14px;
    padding-left: 16px;
    padding-top: 12px;
    background-color: var(--ion-item-background);
}

.text-placeholder-select-all {
    color: #e6e6e6;
    font-weight: 300;
}

.badge-segment-md {
    font-size: 11px;
    position: absolute;
    top: 5px;
    left: 53%;
}

.two-option-item-color {
    display: flex;
    position: absolute;
    right: 0;
    width: 6px;
    height: 100%;
}

.two-option-item-color .option--item-delete-style {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-danger);
}

.two-option-item-color .option--item-edit-style {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-success);
}

.option--item-delete-style {
    position: absolute;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: var(--ion-color-danger);
}

.option--item-edit-style {
    position: absolute;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: var(--ion-color-success);
}

.svg-color {
    fill: var(--ion-color-medium);
}

.svg-color-light {
    fill: var(--ion-color-light)
}

.multicolor-bar {
    margin: 20px 20%;
}

.multicolor-bar .values .value {
    float: left;
    text-align: center;
}

.multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
}

.multicolor-bar .bars .bar {
    float: left;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
}

.multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.multicolor-bar .legends {
    text-align: center;
}


.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
    font-size: 25px;
    vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}

/*---- Override Ionic  ----*/

ion-slides {
    height: 100%;
}

ion-searchbar.none-shadow {
    --box-shadow: none !important;
}

ion-label.sc-ion-select-popover {
    align-items: center;
    display: flex;
    padding: 10px 20px 10px 0;
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
    white-space: normal !important;
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
    white-space: normal !important;
}

@media (max-width: 768px) {
    .popover-content {
        width: 80% !important;
    }
}

@media (min-width: 768px) {
    .popover-content {
        width: 40% !important;
    }
}

