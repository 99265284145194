@import url(https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&display=swap);
/* Ionic Variables and Theming: https://ionicframework.com/docs/theming/color-generator */

:root {
    --ion-font-family: -apple-system, 'Sarabun', "Helvetica Neue", "Roboto", sans-serif;

    --ion-color-primary: #2694e8;
    --ion-color-primary-rgb: 38, 148, 232;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #2182cc;
    --ion-color-primary-tint: #3c9fea;

    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    --ion-color-tertiary: #6370f8;
    --ion-color-tertiary-rgb: 99, 112, 248;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5763da;
    --ion-color-tertiary-tint: #737ef9;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-light-dark: #ffffff;
    --ion-color-light-dark-rgb: 255,255,255;
    --ion-color-light-dark-contrast: #000000;
    --ion-color-light-dark-contrast-rgb: 0,0,0;
    --ion-color-light-dark-shade: #e0e0e0;
    --ion-color-light-dark-tint: #ffffff;
}

/* --------------  Dark Mode -------------- */

body.dark {
    --ion-color-primary: #3865b2;
    --ion-color-primary-rgb: 56, 101, 178;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #31599d;
    --ion-color-primary-tint: #4c74ba;

    --ion-color-secondary: #3394c1;
    --ion-color-secondary-rgb: 51, 148, 193;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #2d82aa;
    --ion-color-secondary-tint: #479fc7;

    --ion-color-tertiary: #705e9c;
    --ion-color-tertiary-rgb: 112, 94, 156;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #635389;
    --ion-color-tertiary-tint: #7e6ea6;

    --ion-color-light-dark: #000000;
    --ion-color-light-dark-rgb: 0,0,0;
    --ion-color-light-dark-contrast: #ffffff;
    --ion-color-light-dark-contrast-rgb: 255,255,255;
    --ion-color-light-dark-shade: #000000;
    --ion-color-light-dark-tint: #1a1a1a;
}

/* --------------  iOS Dark Theme -------------- */

.ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: rgba(0, 0, 0, 0);
    --ion-item-border-color: var(--ion-color-step-250);
    --ion-card-background: #1c1c1d;

    --ion-color-light-dark: #000000;
    --ion-color-light-dark-rgb: 0,0,0;
    --ion-color-light-dark-contrast: #ffffff;
    --ion-color-light-dark-contrast-rgb: 255,255,255;
    --ion-color-light-dark-shade: #000000;
    --ion-color-light-dark-tint: #1a1a1a;
}

.ios body.dark ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
    --ion-item-background: var(--ion-color-step-150);
}


/* -------------- Material Design Dark Theme -------------- */

.md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;
    --ion-item-border-color: var(--ion-color-step-150);
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;
}

/* -------------- Create New Color -------------- */

.light-dark {
    --ion-color-base: var(--ion-color-light-dark);
    --ion-color-base-rgb: var(--ion-color-light-dark-rgb);
    --ion-color-contrast: var(--ion-color-light-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-dark-shade);
    --ion-color-tint: var(--ion-color-light-dark-tint);
}

* {
    font-family: 'Sarabun', sans-serif;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: .25rem !important;
}

.m-2 {
    margin: .5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.mt-1 {
    margin-top: .25rem !important;
}

.mr-1 {
    margin-right: .25rem !important;
}

.mb-1 {
    margin-bottom: .25rem !important;
}

.ml-1 {
    margin-left: .25rem !important;
}

.mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
}

.mt-2 {
    margin-top: .5rem !important;
}

.mr-2 {
    margin-right: .5rem !important;
}

.mb-2 {
    margin-bottom: .5rem !important;
}

.ml-2 {
    margin-left: .5rem !important;
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.my-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: .25rem !important;
}

.p-2 {
    padding: .5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.pt-1 {
    padding-top: .25rem !important;
}

.pr-1 {
    padding-right: .25rem !important;
}

.pb-1 {
    padding-bottom: .25rem !important;
}

.pl-1 {
    padding-left: .25rem !important;
}

.px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
}

.pt-2 {
    padding-top: .5rem !important;
}

.pr-2 {
    padding-right: .5rem !important;
}

.pb-2 {
    padding-bottom: .5rem !important;
}

.pl-2 {
    padding-left: .5rem !important;
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pr-5 {
    padding-right: 3rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.pl-5 {
    padding-left: 3rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.progress-bar-gray-container-custom {
    background-color: #d0cfcf;
    border-radius: 50px;
    overflow: hidden
}

.progress-bar-container-custom {
    background-color: #eb445a;
    border-radius: 50px;
    overflow: hidden;
}

.label-progress-bar-custom {
    color: #fff;
    font-weight: bold;
    margin-left: 60px;
    white-space: nowrap;
}

.progress-type-label {
    display: flex;
    justify-content: space-between;
}

.grid-progress-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.box-shadow-default {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px;
}

.change-image-profile-button {
    width: 40px;
    height: 40px;
    position: relative;
    right: -50px;
    bottom: 40px;
}

.flex-box-center {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-circle {
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.line-horizontal {
    width: 100%;
    border-bottom: solid 1.5px rgba(0, 0, 0, 0.13);
    border-bottom: solid 1.5px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))
}

.text-label-item {
    display: block;
    color: var(--ion-text-color);
    font-size: 14px;
    padding-left: 16px;
    padding-top: 12px;
    background-color: var(--ion-item-background);
}

.text-placeholder-select-all {
    color: #e6e6e6;
    font-weight: 300;
}

.badge-segment-md {
    font-size: 11px;
    position: absolute;
    top: 5px;
    left: 53%;
}

.two-option-item-color {
    display: flex;
    position: absolute;
    right: 0;
    width: 6px;
    height: 100%;
}

.two-option-item-color .option--item-delete-style {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-danger);
}

.two-option-item-color .option--item-edit-style {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-success);
}

.option--item-delete-style {
    position: absolute;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: var(--ion-color-danger);
}

.option--item-edit-style {
    position: absolute;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: var(--ion-color-success);
}

.svg-color {
    fill: var(--ion-color-medium);
}

.svg-color-light {
    fill: var(--ion-color-light)
}

.multicolor-bar {
    margin: 20px 20%;
}

.multicolor-bar .values .value {
    float: left;
    text-align: center;
}

.multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
}

.multicolor-bar .bars .bar {
    float: left;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
}

.multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.multicolor-bar .legends {
    text-align: center;
}


.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
    font-size: 25px;
    vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}

/*---- Override Ionic  ----*/

ion-slides {
    height: 100%;
}

ion-searchbar.none-shadow {
    --box-shadow: none !important;
}

ion-label.sc-ion-select-popover {
    align-items: center;
    display: flex;
    padding: 10px 20px 10px 0;
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
    white-space: normal !important;
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
    white-space: normal !important;
}

@media (max-width: 768px) {
    .popover-content {
        width: 80% !important;
    }
}

@media (min-width: 768px) {
    .popover-content {
        width: 40% !important;
    }
}


